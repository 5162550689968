import { createStylesUtils } from '../../styles/stylesUtils';

export const pickYourPlanStyles = createStylesUtils({
  planContainer: {
    padding: '40px',
    background: 'white',
    borderRadius: '36px',
    boxShadow: ' 0px 20px 40px 0px rgba(0, 0, 0, 0.10)',
    width: '333px',
    boxSizing: 'border-box',
    position: 'relative',
    border: '4px solid transparent',

    '&:hover': {
      cursor: 'pointer',
    },
  },

  planText: {
    fontWeight: 500,
    color: '#696974',
    fontSize: '14px',
    marginTop: '10px',
  },

  personalisedPricing: {
    fontWeight: 600,
    color: '#292D3266',
    fontSize: '24px',
  },

  price: {
    fontWeight: 600,
    fontSize: '36px',
  },

  oldPrice: {
    fontSize: '24px',
    fontWeight: 600,
    textDecorationLine: 'line-through',
    color: '#292D3266',
  },

  usersLabel: {
    color: '#0ABAB5',
    fontWeight: 600,
    fontSize: 16,
  },

  geneticInsightsContainer: {
    background: '#0ABAB5',
    borderRadius: 12,
    margin: '20px 0px',
    padding: '4px 8px',
  },

  geneticInsightsLabel: {
    color: 'white',
    fontSize: '13px',
    fontWeight: 500,
  },

  selected: {
    border: '4px solid #0ABAB5',
  },

  selectedIcon: {
    width: '36px',
    height: '36px',
    position: 'absolute',
    top: '-10px',
    right: '-10px',
  },

  planContainerDisabled: {
    '& p, span, h2': {
      color: '#292D3266',
    },
    '&:hover': {
      cursor: 'default',
    },
  },

  recommendedContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
  },

  recommended: {
    fontWeight: 600,
    fontSize: '16px',
    borderRadius: '20px',
    border: '3.49px solid var(--Mint--100, #0ABAB5)',
    padding: '8px 16px',
    color: '#0ABAB5',
    transform: 'translateY(-50%)',
    backgroundColor: 'white',
    zIndex: 100,
  },

  mimumEmployeesRequired: {
    padding: '40px',
    paddingTop: '60px',
    backgroundColor: '#EBEDF0',
    borderRadius: '0px 0px 36px 36px',
    marginTop: '-21px',
  },

  minimumEmployeesText: {
    color: '#E84D8A',
    fontSize: '12px',
    fontWeight: 600,
  },

  vatText: {
    color: '#292D3266',
    fontSize: '16px',
    fontWeight: 600,
  },

  vatSuperScript: {
    color: '#292D3266',
    fontSize: '12px',
    fontWeight: 600,
    verticalAlign: 'super',
  },
});
