import { Fade, Stack, Typography, Box } from '@mui/material';
import { smeStyles } from '../styles/sme';
import { FADE_TIMEOUT } from '../styles/stylesUtils';
import { images } from '../assets/assets';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../redux/store';
import { checkoutActions } from '../redux/checkout';
import { useGetPlansQuery } from '../redux/api';
import { pickYourPlanStyles } from './styles.ts/PickYourPlan.styles';
import { useTranslation } from 'react-i18next';

export default function PickYourPlan() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();

  const coupon = useMemo(() => {
    const searchParamCoupon = searchParams.get('coupon');
    return searchParamCoupon ? searchParamCoupon : undefined;
  }, [searchParams]);

  const { data: plans } = useGetPlansQuery(coupon);

  const [selected, setSelected] = useState(0);

  const numberOfEmployees = useMemo(() => {
    const numberOfEmployeesParam = searchParams.get('numberOfEmployees');
    if (!numberOfEmployeesParam) {
      return 1;
    } else {
      const parsed = parseInt(numberOfEmployeesParam);
      if (isNaN(parsed)) {
        return 1;
      } else {
        return parsed;
      }
    }
  }, [searchParams]);

  const onContinuePressed = useCallback(async () => {
    if (!plans) {
      return;
    }

    if (selected === 2) {
      navigate('/sme/7');
      return;
    }

    if (plans.discount && plans.discount.plan_id !== plans.plans[selected].id) {
      const response = confirm(t('theCouponYouHaveEntered'));
      if (response) {
        dispatch(
          checkoutActions.setCheckoutData({
            planId: plans.plans[selected].id,
            coupon: undefined,
          }),
        );

        navigate(`/sme/4`);
        return;
      } else {
        return;
      }
    }

    dispatch(
      checkoutActions.setCheckoutData({
        planId: plans.plans[selected].id,
        coupon,
      }),
    );

    navigate(`/sme/4`);
  }, [coupon, dispatch, navigate, plans, selected, t]);

  const pakaDisabled = useMemo(() => {
    if (!plans) {
      return true;
    }

    return plans.plans[1].min_number_of_seats > numberOfEmployees;
  }, [numberOfEmployees, plans]);

  const pokoDisabled = useMemo(() => {
    if (!plans) {
      return true;
    }

    return numberOfEmployees < 500;
  }, [numberOfEmployees, plans]);

  const recommended = useMemo(() => {
    return (
      <Stack
        direction={'row'}
        justifyContent={'center'}
        sx={pickYourPlanStyles.recommendedContainer}
      >
        <Typography sx={pickYourPlanStyles.recommended}>
          {t('recommended')}
        </Typography>
      </Stack>
    );
  }, [t]);

  useEffect(() => {
    if (!plans) {
      return;
    }

    if (!pokoDisabled) {
      setSelected(2);
    } else if (!pakaDisabled) {
      setSelected(1);
    } else {
      setSelected(0);
    }
  }, [pakaDisabled, plans, pokoDisabled]);

  if (!plans) {
    return null;
  }

  return (
    <Fade in={true} timeout={FADE_TIMEOUT} appear={true}>
      <Stack
        sx={[smeStyles.content, { maxWidth: '100%' }]}
        justifyContent={'space-between'}
        alignSelf={'center'}
        alignItems={'center'}
      >
        <Typography variant={'h1'}>{t('pickYourPlan')}</Typography>

        <Stack
          flexDirection={'row'}
          alignSelf={'center'}
          gap={'20px'}
          alignItems={'center'}
          sx={{ marginTop: '40px' }}
        >
          <Stack
            flexDirection={'column'}
            sx={[
              pickYourPlanStyles.planContainer,
              selected === 0 && pickYourPlanStyles.selected,
            ]}
            onClick={() => setSelected(0)}
          >
            {numberOfEmployees < plans.plans[1].min_number_of_seats &&
              recommended}

            <Typography sx={{ fontSize: 32 }} variant="h2">
              {t('piki')}
            </Typography>
            <Typography sx={pickYourPlanStyles.planText}>
              {t('beautifullySimpleSYD')}
            </Typography>

            <Stack
              direction={'row'}
              alignItems={'center'}
              gap="8px"
              sx={{ margin: '20px 0px' }}
            >
              <Box component={'img'} src={images.plans.piki} />
              <Typography sx={pickYourPlanStyles.usersLabel}>
                {t('employeesNumber', {
                  max: plans.plans[1].min_number_of_seats - 1,
                })}
              </Typography>
            </Stack>

            <Stack direction={'row'} alignItems={'center'} gap={'10px'}>
              <Typography sx={pickYourPlanStyles.price}>
                £{plans.plans[0].price}
              </Typography>
              {plans.plans[0].price !== plans.plans[0].fullPrice && (
                <Typography sx={pickYourPlanStyles.oldPrice}>
                  £{plans.plans[0].fullPrice}
                </Typography>
              )}
            </Stack>

            <Typography sx={pickYourPlanStyles.planText}>
              {t('perPersonMonth')}
            </Typography>

            {selected === 0 && (
              <Box
                component={'img'}
                src={images.plans.checkSelected}
                sx={pickYourPlanStyles.selectedIcon}
              />
            )}
          </Stack>

          <Stack>
            <Stack
              flexDirection={'column'}
              sx={[
                pickYourPlanStyles.planContainer,
                selected === 1 && pickYourPlanStyles.selected,
                pakaDisabled && pickYourPlanStyles.planContainerDisabled,
              ]}
              onClick={() => {
                if (!pakaDisabled) {
                  setSelected(1);
                }
              }}
            >
              {numberOfEmployees >= plans.plans[1].min_number_of_seats &&
                numberOfEmployees < 500 &&
                recommended}

              <Typography sx={{ fontSize: 32 }} variant="h2">
                {t('paka')}
              </Typography>
              <Typography sx={pickYourPlanStyles.planText}>
                {t('declutterLifeQuality')}
              </Typography>

              <Stack
                direction={'row'}
                alignItems={'center'}
                gap="8px"
                sx={{ margin: '20px 0px' }}
              >
                <Box
                  component={'img'}
                  src={
                    pakaDisabled ? images.plans.pakaDisabled : images.plans.paka
                  }
                />
                <Typography sx={pickYourPlanStyles.usersLabel}>
                  {t('employeesNumberRight', {
                    min: plans.plans[1].min_number_of_seats,
                  })}
                </Typography>
              </Stack>

              <Stack direction={'row'} alignItems={'center'} gap={'10px'}>
                <Typography sx={pickYourPlanStyles.price}>
                  £{plans.plans[1].price}
                </Typography>

                {plans.plans[1].price !== plans.plans[1].fullPrice && (
                  <Typography sx={pickYourPlanStyles.oldPrice}>
                    £{plans.plans[1].fullPrice}
                  </Typography>
                )}
              </Stack>

              <Typography sx={pickYourPlanStyles.planText}>
                {t('perPersonMonth')}
              </Typography>

              {selected === 1 && (
                <Box
                  component={'img'}
                  src={images.plans.checkSelected}
                  sx={pickYourPlanStyles.selectedIcon}
                />
              )}
            </Stack>

            {pakaDisabled && (
              <Stack
                justifyContent={'center'}
                alignItems={'center'}
                sx={pickYourPlanStyles.mimumEmployeesRequired}
              >
                <Typography sx={pickYourPlanStyles.minimumEmployeesText}>
                  {t('minimumEmployeesRequired', {
                    min: plans.plans[1].min_number_of_seats,
                  })}
                </Typography>
              </Stack>
            )}
          </Stack>

          <Stack>
            <Stack
              flexDirection={'column'}
              sx={[
                pickYourPlanStyles.planContainer,
                selected === 2 && pickYourPlanStyles.selected,
                pokoDisabled && pickYourPlanStyles.planContainerDisabled,
              ]}
              onClick={() => {
                if (!pokoDisabled) {
                  setSelected(2);
                }
              }}
            >
              {numberOfEmployees >= 500 && recommended}

              <Typography sx={{ fontSize: 32 }} variant="h2">
                {t('poko')}
              </Typography>
              <Typography sx={pickYourPlanStyles.planText}>
                {t('forGameChangers')}
              </Typography>

              <Stack
                direction={'row'}
                alignItems={'center'}
                gap="8px"
                sx={{ margin: '20px 0px' }}
              >
                <Box
                  component={'img'}
                  src={
                    pakaDisabled ? images.plans.pakaDisabled : images.plans.paka
                  }
                />
                <Typography sx={pickYourPlanStyles.usersLabel}>
                  {t('fiveHundredsEmployees')}
                </Typography>
              </Stack>

              <Typography sx={pickYourPlanStyles.personalisedPricing}>
                {t('personalisedPricing')}
              </Typography>

              {selected === 2 && (
                <Box
                  component={'img'}
                  src={images.plans.checkSelected}
                  sx={pickYourPlanStyles.selectedIcon}
                />
              )}
            </Stack>

            {pokoDisabled && (
              <Stack
                sx={pickYourPlanStyles.mimumEmployeesRequired}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Typography sx={pickYourPlanStyles.minimumEmployeesText}>
                  {t('minimum500Employees')}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>

        <Typography sx={pickYourPlanStyles.vatText}>
          {t('importantAllPricesInclude')}{' '}
          <Typography component={'span'} sx={pickYourPlanStyles.vatSuperScript}>
            20%
          </Typography>
        </Typography>

        <LoadingButton
          variant="contained"
          endIcon={<Box component={'img'} src={images.button.arrowRight} />}
          sx={{
            alignSelf: 'center',
            margin: '0px 0px 40px',
          }}
          onClick={onContinuePressed}
        >
          {t('confirm')}
        </LoadingButton>
      </Stack>
    </Fade>
  );
}
